<template>
    <div class="hire flex-row flex-j-center flex-a-start">
        <div class="flex-row flex-j-start flex-a-start">
<!--            <category @select="onCategorySelect"/>-->
            <!--            <banner/>-->
            <!--            <information/>-->
            <div style="width: 0.2rem"></div>
            <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
                <div class="list" style="width: 20rem;padding: 0 0.1rem">
<!--                    <condition-search :list="searchList" @active="onConditionActive"/>-->
                    <select-search :list="searchList" @active="onConditionActive"></select-search>
                    <hire-cell v-for="(item, index) in list" :key="index" :item="item"></hire-cell>
                </div>
                <empty-page v-if="!list||list.length===0" msg="暂无相关求职者"/>
            </refresh-list>
        </div>

    </div>
</template>

<script>
// hire
// import Category from "@/components/home/category";
import RefreshList from "@/components/refresh-list";
import EmptyPage from "@/components/empty-page";
import HireCell from "@/components/hire-cell";
// import ConditionSearch from "@/components/condition-search";
import SelectSearch from "@/components/select-search";
export default {
    name: "hire",
    components: {SelectSearch,
        // ConditionSearch,
        HireCell, EmptyPage, RefreshList,
        // Category
    },
    data() {
        return {
            searchList: [
                {
                    title: '职位', field: 'postName', list: []
                },
                {
                    title: '所属镇街', field: 'areaName', list: []
                },
                {
                    title: '性别', field: 'sex', list: [
                        {
                            name: '不限',
                            value: 0
                        },
                        {
                            name: '男',
                            value: 1
                        },
                        {
                            name: '女',
                            value: 2
                        },
                        
                    ]
                },
                {
                    title: '学历',
                    field: 'education',
                    list: []
                }
            ],
            list: [],
            pageSize: 20,
            pageIndex: 1,
            searchParams: {
                areaName: "",
                postName: "",
                sex: "",
                education: "",
                // keyword: ""
            }
        }
    },
    mounted() {
        this.loadPageList();
        // 监听头部搜索框搜索事件
        window.$onIndexHeaderSearch = (keyword) => {
            this.searchParams.postName = keyword;
            // this.searchParams.keyword = keyword;
            // console.log(keyword)
            this.pageIndex = 1;
            this.loadPageList();
        }
        let postNameList = $app.getStorageSync('businessList') || [];
        postNameList = JSON.stringify(postNameList).replace(/professionList/g, "children");
        this.searchList[0].list = JSON.parse(postNameList);
        
        let areaNameList = $app.getStorageSync('areaList') || [];
        areaNameList = areaNameList.map(item => {
            item.name = item.AreaName;
            item.value = item.AreaName;
            return item;
        });
        this.searchList[1].list = areaNameList;
        let educationList = $app.getEducationOptions();
        educationList = educationList.map((item, index) => {
            return {
                name: item,
                value: index
            }
        });
        this.searchList[3].list = educationList;
    },
    methods: {
        onCategorySelect(item) {
            console.log(item)
            this.searchParams.postName = item.name;
            this.list = [];
            this.pageIndex = 1;
            this.loadPageList();
        },
        onConditionActive(item) {
            if (item.field === 'sex') {
                this.searchParams[item.field] = item.value;
            } else {
                this.searchParams[item.field] = item.name.replace(">", "").replace("面议", "");
            }
            this.pageIndex = 1;
            this.loadPageList();
        },
        async loadPageList() {
            const params = {
                areaName: this.searchParams.areaName || '',
                postName: this.searchParams.postName || "",
                sex: this.searchParams.sex || 0,
                education: this.searchParams.education || '',
                // details: this.searchList[3] || '',
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                memberType: 1,
            };
            const res = await $app.httpGet({
                path: `/Member/GetPageList`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];
            
                newList = newList.map(item => {
                    item.timeText = $app.dateFormatChinese(item.BaseCreateTime);
                    item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                    item.previteName = item.MemberName.substr(0, 1) + "**";
                    if (item.Phone) {
                        item.Phone += "";
                        try {
                            item.previtePhone = item.Phone.substr(0, 3) + "****" + item.Phone.substr(7, 11)
                        } catch (e) {
                            item.previtePhone = "***********"
                        }
                    }
                    return item;
                });
            
                if (this.pageIndex === 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }
        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.hire {
    width: 28rem;
}
</style>
